// Show scroll on
.show-scroll {
  > .ps__rail-x,
  > .ps__rail-y,
  > .ps__rail-x,
  > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x,
  &.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6 !important;
  }
}
