/* Global Styles */
/* Put those styles that can't applied via props */

/* Global styles for react-datepicker ---------------*/
.react-datepicker {
  z-index: 9999 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.chakra-input__right-element {
  z-index: 0 !important;
}
.css-qhjqy9 {
  padding: 1.4em;
}
/* --------------------------------------------------*/

/* Keyframes --------------------------------------- */
@keyframes phone-ring {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.4);
  }
  25% {
    box-shadow: 0 0 0 5px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }
  25% {
    box-shadow: 0 0 0 6px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #ccc;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #c2c2c2;
}


/* --------------------------------------------------*/
.logo-icon {
  color: gray;
}
@media print {
  body * {
    visibility: hidden;
  }
  #feedback-detail-print {
    margin-top: 30px;
    padding: 10px;
  }
  #feedback-detail-print * {
    visibility: visible;
    position: static;
  }
  #booking-detail-print {
    margin-top: 20px;
  }
  #booking-detail-print * {
    visibility: visible;
    position: static;
    background-color: white !important;
  }

  #booking-pdf-table tr {
    border-bottom: 1px solid gray;
  }
  .booking-pdf-table-dashed-border {
    border-bottom: 1px dashed gray;
  }
  .booking-pdf-table-solid-border {
    border-bottom: 1px solid gray;
  }
  .booking-pdf-description {
    color: #555;
  }
  @page {
    size: auto;
  }
  .page-break {
    margin-top: 10rem;
    display: block;
    page-break-before: auto;
  }
}

/* feedback dashboard pdf */
@media print {
  body {
    background-color: #fff !important;
  }
  body * {
    visibility: hidden;
  }
  #feedback-print {
    margin: 0 !important;
    padding: 0 !important;
  }
  #feedback-print * {
    visibility: visible;
    position: static;
    background-color: white !important;
  }

  #invoice-print {
    margin: 0 !important;
    padding: 0 !important;
  }

  #invoice-print * {
    visibility: visible;
    position: static;
    background-color: white !important;
  }

  .invoice-table thead {
    display: table-header-group;
  }
  .invoice-table tfoot {
    display: table-row-group;
  }

  #feedback-pdf-table-body {
    margin: 0 !important;
    padding: 0 !important;
  }
  #feedback-pdf-table-body tr {
    border-bottom: 1px solid #d3d3d3;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #feedback-filter-block > * {
    display: inline-block;
    width: 50%;
    font-family: 'Roboto', sans-serif;
  }
  .filter-keyword-text {
    font-weight: 700;
  }
  #filter-header-text {
    font-weight: 900;
    font-size: 18px;
    display: block;
  }
  .hide-in-print {
    display: none;
  }
}

/* feedback response rating background color */
.rating-tag {
  display: block;
  text-align: center;
  max-width: 3rem;
  font-weight: 500;
  line-height: 2;
  min-height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.875rem;
  color: #fff;
  border-radius: 9999px;
}
#tag-background-color-red {
  background-color: #f56565 !important;
}
#tag-background-color-orange {
  background-color: #ed8936 !important;
}
#tag-background-color-green {
  background-color: #68d391 !important;
}

@media all {
  .page-break {
    display: none;
  }
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
/*Compose Message Header*/
.tox .tox-editor-header {
  z-index: 0 !important;
}
.tox-tinymce {
  border: 2px solid #eee;
  border-radius: 3px !important;
}

.custom-select-container {
  position: relative;
  box-sizing: border-box;
}
.outer-line-span {
  z-index: 9999;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
.all-content-container {
  align-items: center;
  background-color: #ffffff;
  border-color: inherit;
  border-radius: 0.125rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 1px 0px #eff0f6;
  cursor: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 42px;
  outline: 0 !important;
  position: relative;
  transition: all 0.35s ease-in-out;
  box-sizing: border-box;
  opacity: 1;
}
.text-placeholder-and-input-field-container {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 0 10px;
  overflow: hidden;
  box-sizing: border-box;
}
.text-placeholder {
  color: #000000;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
.input-field-inner-container {
  box-sizing: content-box;
  width: 100%;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
}
.input-field {
  width: 100%;
  margin-left: 4px;
  outline: none !important;
}
.input-field::placeholder {
  color: #000000;
}
.seperator-arrow-outer-container {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.line-seperator {
  align-self: stretch;
  background-color: #cbd5e0;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}
.arrow-inner-container {
  color: #000000;
  display: flex;
  padding: 7px 8px;
  box-sizing: border-box;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;
  transition-delay: 0s;
}
.arrow-svg {
  display: inline-block;
  fill: #718096;
  line-height: 1;
  stroke-width: 0;
}

.modal-box {
  width: 100%;
  z-index: 9999;
  position: absolute;
  margin-top: 6px;
  padding-top: 4px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #ddd;
  overflow-y: auto;
  max-height: 330px;
}
.list-item {
  padding-left: 10px;
  font-size: 13px;
  font-weight: 300;
  height: 36px;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
}
.list-item-disabled {
  opacity: 0.4;
}
.list-item:hover {
  background-color: #abd3c0;
  opacity: 0.9;
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.3;
  right: 10px;
  line-height: 9px;
  font-size: 0.8em;
}
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  padding-right: 26px;
}
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  bottom: 50%;
  content: '▲';
}
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  top: 50%;
  content: '▼';
}

/*blink css*/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* booking calendar css */
.scheduler {
  margin-top: 10px !important;
}

table.resource-table tr,
table.scheduler-bg-table tr,
table.scheduler-table tr,
table.resource-table td,
table.scheduler-bg-table td,
table.scheduler-table td {
  border-color: transparent !important;
}

table.scheduler-table tr,
table.scheduler-bg-table td {
  border-right: 1px solid #e3e1e1 !important;
}

table.resource-table th {
  border-right: 0 !important;
}

#RBS-Scheduler-root .anticon {
  vertical-align: middle !important;
}

table.scheduler-bg-table th {
  border-right: 1px solid #e3e1e1 !important;
}

thead .header2-text {
  font-size: 22px;
  font-weight: 600;
}

table.resource-table th {
  text-align: left !important;
  padding-left: 1em;
  font-size: 14px;
}

.scheduler-view {
  margin: 0 !important;
}

.tickets-image ~ .hidden-icon {
  display: none;
}

.hidden-icon:hover {
  display: block !important;
}

.tickets-image:hover ~ .hidden-icon {
  display: block;
}

.primary-accent-color {
  accent-color: #5a9f7e;
}

.message-date-header:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  align-self: center;
  background: #f4f5f9;
  margin-right: 6px;
}

.message-date-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  align-self: center;
  background: #f4f5f9;
  margin-left: 6px;
}

.date-picker-two-months .react-datepicker {
  width: max-content;
}

@media only screen and (max-width: 750px) {
  .date-picker-two-months .react-datepicker {
    width: min-content;
  }
}

/** Invoice Template **/
.invoice-template-container {
  background-color: #fff;
  width: 800px;
  padding: 30px;
}

.invoice-template-container p {
  margin-bottom: 8px;
  font-size: 14px;
}

.invoice-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myval-heading {
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.invoice-user-information {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.myval-sub-heading {
  font-weight: 300;
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 2px;
}

.myval-information {
  text-align: right;
  font-size: 12px;
}

.invoice-template-container ul {
  list-style-type: none;
  font-weight: 400;
  margin-bottom: 10px;
}

.invoice-number {
  margin-bottom: 48px;
  margin-top: 48px;
  font-weight: 700;
  font-size: 18px;
}

.invoice-table {
  width: 100%;
}

.invoice-table th {
  padding: 5px;
  border: 1px solid #2d2d32;
}

.invoice-table td {
  padding: 5px;
  border-right: 1px solid #2d2d32;
  border-left: 1px solid #2d2d32;
}

.invoice-table .services {
  width: 440px;
}

.invoice-amount td {
  font-weight: 500;
  border: 1px solid #2d2d32;
}

.w-70 {
  width: 70%;
}

.invoice-table-payments {
  width: 100%;
  text-align: right;
  margin-bottom: 12px;
  margin-top: 16px;
}

.invoice-table-payments th {
  padding: 5px;
  border: 1px solid #2d2d32;
}

.invoice-table-payments td {
  padding: 5px;
  border-left: 1px solid #2d2d32;
  border-right: 1px solid #2d2d32;
}

.divider {
  border-top: 1px solid #5b5b60;
}

.invoice-footer {
  color: #5b5b60;
  font-size: 12px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}

.mt-4 {
  margin-top: 4px;
}

/** Invoice Template **/

.text-align-right {
  float: right;
}

.fade {
  color: rgb(172, 170, 170);
}
